import profile from "./profile";

var permissionsIds = [];

function populate(userPermissions) {
    userPermissions.map(item => {
        // Solução temporária para bug ao gerar submenu
        return permissionsIds.push(item.id);
        // permissionsIds.push(item.id);
        // return populate(item.children);
    });
}
populate(profile.permissions);

const permissions = {
    processos: permissionsIds.includes(1),
    kanban: permissionsIds.includes(48),
    analytics: permissionsIds.includes(49),
    agenda: permissionsIds.includes(50),
    benchmark: permissionsIds.includes(51),
    armazem: permissionsIds.includes(70),
    transporte: permissionsIds.includes(53),
    orcamento: permissionsIds.includes(54),
    helpcenter: permissionsIds.includes(55),
    recebimento: permissionsIds.includes(56),
    estoque: permissionsIds.includes(57),
    pickingepacking: permissionsIds.includes(58),
    expedicao: permissionsIds.includes(59),
    canHideFreight: permissionsIds.includes(61),
    manual: permissionsIds.includes(62),
    hideProcess: permissionsIds.includes(63),
    mapa: permissionsIds.includes(71),
    powerbi: permissionsIds.includes(72),
    excludePickingPack: permissionsIds.includes(73),
};

export default permissions;
